import { toast } from "react-toastify";

export const updateLanguageForUser = async (
    language,
    router,
    pathname,
    session = null,
) => {
    if (session && session.user.language !== language) {
        const response = await fetch("/api/user/put/language", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ language }),
        });

        const fetchedData = await response.json();

        if (!response.ok) {
            toast.error(fetchedData.message);
            return;
        }

        const event = new Event("visibilitychange");
        document.dispatchEvent(event);
    }

    let tmpUrl = pathname;
    if (language !== "fr") {
        tmpUrl = `/${language}${tmpUrl}`;
    }
    window.location.href = tmpUrl;

    // Redirect to new url
    // router.push(pathname, {
    //     locale: language,
    // });
};

export const redirectForNewLocale = async (
    newLocale,
    router,
    pathname,
    cookies,
    session = null,
) => {
    // Create new cookie if user log out
    if (cookies.get("believemy-locale") !== undefined) {
        await cookies.remove("believemy-locale", { path: "/" });
    }
    await cookies.set("believemy-locale", newLocale, { path: "/" });

    // If user is connected
    if (session && session.user) {
        // Update user language
        updateLanguageForUser(newLocale, router, pathname, session);
    } else {
        // Redirect to new url
        router.push(pathname, {
            locale: newLocale,
        });
    }
};
