"use client";

import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
    InstantSearch,
    SearchBox,
    useInfiniteHits,
    Index,
} from "react-instantsearch";
import searchClient from "@/config/algolia";
import Image from "next/image";
import { Link } from "@/lib/i18n";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";

function Hit({ hit }) {
    if (!hit) return <div>Loading...</div>;

    let link = `/r/${hit.slug}`;
    if (hit.summary) {
        if (hit.category == "course") {
            link = `/course/${hit.slug}`;
        } else {
            link = `/accelerators/${hit.slug}`;
        }
    }

    return (
        <Link href={link}>
            <article className="flex md:flex-row flex-col md:gap-3 bg-transparent hover:bg-primary hover:bg-opacity-5 duration-100 md:py-5 py-1 px-5">
                <div className="md:block hidden">
                    <div className="w-[190px] aspect-[16/9] bg-black relative">
                        {hit.cover && (
                            <Image
                                src={hit.cover}
                                alt={hit.title || "Image"}
                                width={1920 / 4}
                                height={1080 / 4}
                                className="w-full h-full object-cover"
                            />
                        )}
                    </div>
                </div>
                <div>
                    <h2 className="dark:text-white text-lg font-bold font-sourceSans">
                        {hit.title || "Untitled"}
                    </h2>
                    <p className="text-sm text-gray-500">
                        {hit.description
                            ? `${hit.description.slice(0, 150)}...`
                            : `${hit.summary.slice(0, 150)}...` ||
                              "Aucune description disponible."}
                    </p>
                </div>
            </article>
        </Link>
    );
}

export function InfiniteHits(props) {
    const { items, isLastPage, showMore } = useInfiniteHits(props);
    const sentinelRef = useRef(null);

    useEffect(() => {
        if (sentinelRef.current !== null) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isLastPage) {
                        showMore();
                    }
                });
            });

            observer.observe(sentinelRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [isLastPage, showMore]);

    return (
        <div>
            {items.length != 0 ? (
                items.map((hit) => (
                    <div key={hit.objectID}>
                        <Hit hit={hit} />
                    </div>
                ))
            ) : (
                <div className="px-5 pb-5">{m.SpeedSearch_emptyResults()}</div>
            )}

            <div ref={sentinelRef} aria-hidden="true" />
        </div>
    );
}

export default function SpeedSearch() {
    const [isSearching, setIsSearching] = useState(false);
    const locale = languageTag();

    useEffect(() => {
        if (isSearching) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isSearching]);

    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-7 h-7 mr-4 hover:text-primary cursor-pointer"
                onClick={() => setIsSearching(!isSearching)}
            >
                <g fill="none" stroke="currentColor" strokeWidth="1.5">
                    <circle cx="11.5" cy="11.5" r="9.5"></circle>
                    <path strokeLinecap="round" d="M18.5 18.5L22 22"></path>
                </g>
            </svg> */}
            <div
                className="flex items-center gap-1 group bg-[#06050F] bg-opacity-5 dark:bg-white dark:bg-opacity-10 md:hover:bg-[#06050F] dark:md:hover:bg-white dark:md:hover:bg-opacity-10 md:hover:bg-opacity-5 cursor-pointer hover:bg-primary dark:hover:bg-primary rounded-full p-2 md:w-52 text-sm border border-transparent hover:border-primary duration-100"
                onClick={() => setIsSearching(!isSearching)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 md:group-hover:text-black group-hover:text-white dark:group-hover:text-white"
                    viewBox="0 0 24 24"
                >
                    <g fill="none" stroke="currentColor" strokeWidth="1.9">
                        <circle cx="11.5" cy="11.5" r="9.5"></circle>
                        <path strokeLinecap="round" d="M18.5 18.5L22 22"></path>
                    </g>
                </svg>
                <input
                    type="text"
                    placeholder={m.SpeedSearch_placeholder()}
                    className="bg-transparent md:block hidden"
                />
            </div>
            {isSearching &&
                createPortal(
                    <div
                        className={`top-0 left-0 bottom-0 right-0 w-full min-h-full overflow-y-auto transition-all duration-200 ease-in-out bg-black bg-opacity-80 fixed background-blur`}
                        style={{ zIndex: 100 }}
                        onClick={() => setIsSearching(false)}
                    >
                        <div
                            className="bg-white dark:bg-bgDark-840 border mx-auto my-5 border-transparent dark:border-box-outline rounded-xl xl:w-5/12 lg:w-7/12 md:w-9/12 w-11/12"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-between items-center p-5">
                                <h1 className="text-lg dark:text-white font-bold">
                                    {m.SpeedSearch_title()}
                                </h1>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.1em"
                                    height="1.1em"
                                    viewBox="0 0 24 24"
                                    className="text-primary hover:text-primary-100 cursor-pointer"
                                    onClick={() => setIsSearching(false)}
                                >
                                    <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10M8.97 8.97a.75.75 0 0 1 1.06 0L12 10.94l1.97-1.97a.75.75 0 0 1 1.06 1.06L13.06 12l1.97 1.97a.75.75 0 0 1-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 0 1-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 0 1 0-1.06"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <section>
                                <InstantSearch
                                    searchClient={searchClient}
                                    insights={true}
                                >
                                    <div className="w-full px-5">
                                        <SearchBox
                                            placeholder={m.SpeedSearch_search_placeholder()}
                                            classNames={{
                                                root: "w-full",
                                                form: "w-full relative",
                                                input: "input",
                                                resetIcon: "hidden",
                                                submitIcon: "hidden",
                                            }}
                                            autoFocus
                                        />
                                    </div>

                                    {/* Recherche dans l'index "courses" */}
                                    <Index indexName={`courses-${locale}`}>
                                        <h2 className="text-xl font-bold mt-5 px-5 dark:text-white">
                                            {m.SpeedSearch_courses()}
                                        </h2>
                                        <div className="mt-1">
                                            <InfiniteHits
                                                hitComponent={Hit}
                                                showPrevious={false}
                                                translations={{
                                                    loadPrevious:
                                                        m.SpeedSearch_loadMore(),
                                                    loadMore:
                                                        m.SpeedSearch_loadMore(),
                                                }}
                                                classNames={{
                                                    loadMore:
                                                        "text-center w-full py-2 text-primary font-bold",
                                                }}
                                            />
                                        </div>
                                    </Index>

                                    {/* Recherche dans l'index "articles" */}
                                    <Index indexName={`articles-${locale}`}>
                                        <h2 className="text-xl font-bold mt-5 px-5 dark:text-white">
                                            {m.SpeedSearch_articles()}
                                        </h2>
                                        <div className="mt-1">
                                            <InfiniteHits
                                                hitComponent={Hit}
                                                showPrevious={false}
                                                translations={{
                                                    loadPrevious:
                                                        m.SpeedSearch_loadMore(),
                                                    loadMore:
                                                        m.SpeedSearch_loadMore(),
                                                }}
                                                classNames={{
                                                    loadMore:
                                                        "text-center w-full py-2 text-primary font-bold",
                                                }}
                                            />
                                        </div>
                                    </Index>
                                </InstantSearch>
                            </section>
                        </div>
                    </div>,
                    document.body,
                )}
        </>
    );
}
